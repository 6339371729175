import React, { useEffect, useState } from 'react'
import Parser from 'html-react-parser';
import axios from 'axios'
import {isMobile} from 'react-device-detect';
import PropTypes from 'prop-types'
import $ from "jquery"

function MSolicitarPartModal(props) {
    const {
        title,
        name,
        fc_DESCRIPCION,
        lstPlanContratacionxPlan,
        modalRef,
        openSales,
        fi_SERVICIO,
    } = props

    const location = window.location.href.toLowerCase().indexOf("/negocios/")
    const isNegocios = location > -1 ? true : false;

    const [callCond, setCallCond] = useState(false)
    const [alertCond, setAlertCond] = useState(false)
    const [phoneCode, setPhoneCode] = useState("+569")
    const [localTelefono_tipo, setLocalTelefono_tipo] = useState("movil")
    const [maxlengthPhone, setMaxlengthPhone] = useState(8)
    const [statusClass, setStatusClass] = useState('')
    const [localTelefono, setLocalTelefono] = useState('')
    const [errorField, setErrorField] = useState('')
    const [notification, setNotification] = useState('')
    const [inputDisabled, setInputDisabled] = useState(false)
    const [spanSending, setSpanSending] = useState(false)
    const [idTrans, setIdTrans] = useState("");
    const [rut, setRut] = useState('')
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState({})
    const numberValid = value => {
        return isNaN(value) ? false : true
    }
    const onChangeNumber = (value, f, l) => {
        const letter = value.length > 8 ? value[value.length-1].toLowerCase().indexOf("k") : -1
        const regexpEmoticons = /^[0-9kK]+$/;
        if (value.match(regexpEmoticons)) {
            
            if(value.match(regexpEmoticons)){
                console.log("......", + value)
                f(value)   
            }     
        }else if(value.length == 0){
            f("")
        }
    }

    const onFocusRut = value => {
        setRut(value.replace('-',''))
    }

    const onBlurRut = value => {
        console.log("blur")
        if(value.length > 1){
            let rutFormat = `${value.slice(0,value.length - 1)}-${value.charAt(value.length - 1)}`
            // console.log("rutFormat", rutFormat)
            setRut(rutFormat)
        } else {
            setRut('')
        }
    }

    const url = window.location.href;
    const pageNoLlamar = url.includes("negocios/ofertaclientes/")

    
    function clearClassStatusPlanComparador(clearAll = false) {
        setStatusClass('')
        setErrorField('')
        setAlertCond(false)
        setInputDisabled(false)
        setLocalTelefono('')
        setErrors({})
        if(clearAll){
            setRut('')
            setEmail('')
        }
    }
    function phoneTypeComparador(e) {
        let ItemphoneArea = e.target.value
        let phoneAreaCode = ItemphoneArea === "fijo" ? "+56" : "+569";
        let showSelectTelTipo = localStorage.getItem('id_status') && localStorage.getItem('id_status') === "hideTipo" ? true : false
        if(showSelectTelTipo){
            phoneAreaCode='+56'
        }
        setPhoneCode(phoneAreaCode)
        let maxlengthInput = ItemphoneArea === "fijo" ? 9 : 8;
        if(showSelectTelTipo){
            maxlengthInput=9
        }
        setMaxlengthPhone(maxlengthInput)
        let localTelefonotipo = ItemphoneArea === "fijo" ? "fijo" : "movil";
        setLocalTelefono_tipo(localTelefonotipo)
        clearClassStatusPlanComparador()
        setLocalTelefono('')
    }

    function setInitTypePhone(){
        console.log("setInitTypePhone:::::::::::::::::::::::::")
        let localTelefonotipo = "movil"
        // if(fi_SERVICIO >= 15 && fi_SERVICIO <= 17){
        //     localTelefonotipo = "movil"
        // }
        // if(fi_SERVICIO >= 18 && fi_SERVICIO <= 20){
        //     localTelefonotipo = "fijo"
        // }
        setLocalTelefono_tipo(localTelefonotipo)
        let phoneAreaCode = localTelefonotipo === "fijo" ? "+56" : "+569";
        let showSelectTelTipo = localStorage.getItem('id_status') && localStorage.getItem('id_status') === "hideTipo" ? true : false
        
        if(showSelectTelTipo){
            phoneAreaCode='+56'
        }
        setPhoneCode(phoneAreaCode)
        let maxlengthInput = localTelefonotipo === "fijo" ? 9 : 8;
        if(showSelectTelTipo){
            maxlengthInput=9
        }
        setMaxlengthPhone(maxlengthInput)
        if(fi_SERVICIO >= 15 && fi_SERVICIO <= 20){
            $("#SelectTelTipo").prop('disabled', true)
        }
    }
    function setInitInputRut(){
        // const newLstPlanCont = lstPlanContratacionxPlan.find(lpc => lpc.fc_OPCION_LBX === 'CAL')
        // setRut(newLstPlanCont?.fc_RUT_DEFAULT ? newLstPlanCont?.fc_RUT_DEFAULT : '')
        // setEmail(newLstPlanCont?.fc_mail ? newLstPlanCont?.fc_mail : '')
        // console.log("newLstPlanCont", newLstPlanCont)
    }

    function validateOnlyNumbersComparador(e) {
        clearClassStatusPlanComparador()
        let regex = /^[0-9\b]+$/;
        let inputVal = e.target.value
        if (inputVal === '' || regex.test(inputVal)) {
            // console.log("numberssssssssssssssssssssss::::::" + inputVal)
            setLocalTelefono(inputVal)
        }
    }
    //Función de Error Original
    function errorSetDataStylePlanComparador(maxim = null, errorServer = null) {
        console.log("errorSetDataStylePlanComparador: ")
        let defaultError = "Ha ocurrido un error, vuelva a intentarlo"
        setSpanSending(false)
        if (maxim) {
            setErrorField("El valor debe ser de " + maxim + " caracteres.")
            setStatusClass('error')
        }
        else {
            // if (errorServer != null) {
            if ( errorServer ) {
                defaultError = errorServer
            }
            setNotification(defaultError)
            // $(DivmSolicitar).find("[id=message]").text(defaultError);
        }
    }

    //Función de Error para no mostrar error de cara al cliente.
   function c2cSinError(maxim = null) {
    setStatusClass('success')
    setNotification("Tus datos fueron inscritos correctamente.<br><strong>Pronto nos comunicaremos contigo.</strong>")
    setInputDisabled(true)
    if (maxim) {
        setErrorField("El valor debe ser de " + maxim + " caracteres.")
        setStatusClass('error')
        setInputDisabled(false)
        setSpanSending(false)
    }
}

    function formatoFecha(fecha, formato) {
        const map = {
            dd: ("0" + (fecha.getDate())).slice(-2),
            mm: ("0" + (fecha.getMonth() + 1)).slice(-2),
            yy: fecha.getFullYear(),
            HH: ("0" + (fecha.getHours())).slice(-2),
            mi: ("0" + (fecha.getMinutes())).slice(-2),
            ss: fecha.getSeconds(),
            ms: fecha.getMilliseconds()
        }
        return formato.replace(/dd|mm|yy|yyy|HH|mi|ss|ms/gi, matched => map[matched])
    }
    var Fn = {
        // Valida el rut con su cadena completa "XXXXXXXX-X"
        validaRut : function (rutCompleto) {
          if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
            return false;
          var tmp   = rutCompleto.split('-');
          var digv  = tmp[1]; 
          var rut   = tmp[0];
          if ( digv == 'K' ) digv = 'k' ;
          return (Fn.dv(rut) == digv );
        },
        dv : function(T){
          var M=0,S=1;
          for(;T;T=Math.floor(T/10))
            S=(S+T%10*(9-M++%6))%11;
          return S?S-1:'k';
        }
      }
    function magazineSubmitPlanComparador(c, urlsendData = null, PlanContratacionCAL) {
        console.log("magazineSubmitPlanComparador.......................")
        let errors = {}
        let showInputRut = localStorage.getItem('version_comparador') && localStorage.getItem('version_comparador') === "RUT" ? true : false
        if (showInputRut){

            if(email !== "" && !(new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,50}$/i).test(email))){
                errors = {
                    ...errors,
                    email: {
                        message: 'El correo es incorrecto'
                    }
                }
            }

            var listaNegraRuts = ['0-0', '11111111-1', '22222222-2', '33333333-3', '44444444-4', '55555555-5', '66666666-6', '77777777-7',
      '88888888-8', '99999999-9', '12312312-3', '23423423-4', '34534534-5', '45645645-6', '56756756-7', '67867867-8', '78978978-9',
      '32132132-1', '43243243-2', '54354354-3', '65465465-4', '76576576-5', '87687687-6', '98798798-7'];
            if (!rut) {
                errors = {
                    ...errors,
                    rut: {
                        message: 'Ingresa tu Rut'
                    }
                }
            } else if(rut.length < 9 || listaNegraRuts.includes(rut) || !Fn.validaRut(rut)){
                errors = {
                    ...errors,
                    rut: {
                        message: 'Ingresa un Rut Válido'
                    }
                }
            }
        }
        setErrors(errors)
        if (localTelefono.length < maxlengthPhone) {
            errorSetDataStylePlanComparador(maxlengthPhone)
            //setAlertCond(true)
        } else if(Object.keys(errors).length === 0) {
            console.log("sending...");
            setSpanSending(true)
            sendDataPlan(urlsendData, PlanContratacionCAL);
            setInputDisabled(true)
            setAlertCond(true)
            clearClassStatusPlanComparador(true)
            // setCallCond(false)
        }
    }

    function successSetDataStyleComparador() {
        setStatusClass('success')
        setNotification("Tus datos fueron inscritos correctamente.<br><strong>Pronto nos comunicaremos contigo.</strong>")
    }

    const searchCharacteristics = (search, lstPlanContratacionxPlan) => {

        const data = lstPlanContratacionxPlan.length ? lstPlanContratacionxPlan : [];
        const newCaractData = data.filter(function (PlanContra) {
            let lstPlan = []

            lstPlan = PlanContra.fc_OPCION_LBX
            // console.log("to search---------- TypeFlag:", lstPlan, search)
            return lstPlan === search
        })
        // console.log("to search---------- TypeFlag:", TypeFlag, search, newCaractData, newCaractData.length)
        return newCaractData

    }

    let PlanContratacionCAL = searchCharacteristics('CAL', lstPlanContratacionxPlan)
    let PlanContratacionCHV = searchCharacteristics('CHV', lstPlanContratacionxPlan)
    let PlanContratacionCLN = searchCharacteristics('CLN', lstPlanContratacionxPlan)
    let PlanContratacionWHA = searchCharacteristics('WHA', lstPlanContratacionxPlan)

    // console.log("PlanContratacion FC_OPCION_LBXto search----------", PlanContratacionCAL, PlanContratacionCHV, PlanContratacionCLN)
    useEffect(() => {
        setInitTypePhone()
        setInitInputRut()
        // eslint-disable-next-line
    },[])
    async function sendDataPlan(urlsendData = null, PlanContratacionCAL) {
        // let C2C = localStorage.getItem('id_vigencia')
        let showInputRut = localStorage.getItem('version_comparador') && localStorage.getItem('version_comparador') === "RUT" ? true : false
        //var idTransV2 = ""
        // if (showInputRut){
          //  idTransV2 = formatoFecha(new Date(), 'ddmmyyHHmissms');
            //setIdTrans(idTransV2)
        // }
        console.log("************idTrans::::" + idTrans);
        let urlAjaxsendData = "sendDataPersonas"
        if (urlsendData != null) {
            urlAjaxsendData = urlsendData;
        }

        let rutVal = showInputRut ? rut : PlanContratacionCAL.fc_RUT_DEFAULT ? PlanContratacionCAL.fc_RUT_DEFAULT : ""
        let mailVal = showInputRut ? email : PlanContratacionCAL.fc_mail ? PlanContratacionCAL.fc_mail : "mail@mail.com"
        var SendDataPersonasRequest = {
            localRut1: rutVal,
            localTelefono: localTelefono ? localTelefono : "",
            localServicio: PlanContratacionCAL.fc_SERVICIO ? PlanContratacionCAL.fc_SERVICIO : "",
            localSegmento: PlanContratacionCAL.fc_SEGMENTO ? PlanContratacionCAL.fc_SEGMENTO : "",
            localCliente: PlanContratacionCAL.fc_TIPOCLIENTE ? PlanContratacionCAL.fc_TIPOCLIENTE : "",
            localTelefono_tipo: localTelefono_tipo ? localTelefono_tipo : "",
            localProducto: PlanContratacionCAL.fc_PRODUCTO ? PlanContratacionCAL.fc_PRODUCTO : "",
            localCanal: PlanContratacionCAL.fc_CANAL ? PlanContratacionCAL.fc_CANAL : "",
            localId_c2c: PlanContratacionCAL.fc_ID_C2C ? PlanContratacionCAL.fc_ID_C2C : "",
            localMail: mailVal,
            fc_dato_env: idTrans,
            contextURL: window.location.href
        };

        console.log("Entramos en SendDataPersonasRequest", SendDataPersonasRequest);        
        let url_envio_formulario = localStorage.getItem('url_envio_formulario')
        
        // var text = JSON.stringify(SendDataPersonasRequest);
        //     text = text.replaceAll('"', '')
        //     text = text.replaceAll(':', '=')
        //     text = text.replaceAll(',', '&')
        //     text = text.replaceAll('{', '')
        //     text = text.replaceAll('}', '')
            
        //     console.log("SendDataPersonasRequest:::::::", text)

        var jsonSendData = JSON.stringify(SendDataPersonasRequest);
        console.log("Entramos en sendDataPersonas", urlAjaxsendData);
        console.log(jsonSendData);
        console.log("url_envio_formulario", url_envio_formulario);
        const configMagazineService = {
            method: 'POST',
            url: url_envio_formulario,
            data: jsonSendData,
            headers: {
                'Accept': "application/json",
                'Content-Type': "application/json"
            },
        };
        
            try {
                let response = await axios(configMagazineService)
                let json_c2cwsResponse = response?.data;
                
                successSetDataStyleComparador()
                let getIdTrans = response?.data?.localIdTrans ? response?.data?.localIdTrans : json_c2cwsResponse.split('id_trans":')[1].split(',')[0]
                !showInputRut  ? setIdTrans(getIdTrans) : null
                
                // clearClassStatusPlanComparador()
                setAlertCond(true)
                console.log("response- configMagazineService389------------------------->", response, json_c2cwsResponse);
            } catch (error) {
                errorSetDataStylePlanComparador(null, null)
                setStatusClass('error')
                setInputDisabled(false)
                console.log("response- configMagazineService398-------------------------> Error.....", error);                
            }
            
        
    }
    // eslint-disable-next-line
    function json_c2cws(data) {
        //	console.log(dtl_tipo_usuario+' console');
        var respuesta = JSON.parse(data);
        if (respuesta.desc === 'ok') {
            console.log('resultado: '+ respuesta.resultado);
            console.debug('respuesta WS: ', data);
            // $('#FormSection').hide();
            // $('#thanksForm').show();		
        } else {
            console.log('resultado Error: '+ respuesta);
            // $('#continuarButton').show();
            // $('#enviandoButton').hide();	
            // $("#continuarButton").attr("disabled", false);	
            // $("#div-error_autorizo").show();
            // $("#error_autorizo").html('Ocurrio un error, favor de intentar de nuevo más tarde.');
        }
        
    };
    console.log("isMobile", isMobile)
    

    const closeModalPrimary = () => modalRef.current.close()

    const handleOpenSales = () =>{
        closeModalPrimary()
        openSales()
    }
    let showInputRut = localStorage.getItem('version_comparador') && localStorage.getItem('version_comparador') === "RUT" ? true : false
    let showSelectTelTipo = localStorage.getItem('id_status') && localStorage.getItem('id_status') === "hideTipo" ? true : false

    let rutVal = showInputRut ? rut : PlanContratacionCAL[0].fc_RUT_DEFAULT ? PlanContratacionCAL[0].fc_RUT_DEFAULT : ""
    let mailVal = showInputRut ? email : PlanContratacionCAL[0].fc_mail ? PlanContratacionCAL[0].fc_mail : "mail@mail.com"
    let localSer = PlanContratacionCAL[0].fc_SERVICIO ? PlanContratacionCAL[0].fc_SERVICIO : "";
    let localSeg = PlanContratacionCAL[0].fc_SEGMENTO ? PlanContratacionCAL[0].fc_SEGMENTO : "";
    let localCli = PlanContratacionCAL[0].fc_TIPOCLIENTE ? PlanContratacionCAL[0].fc_TIPOCLIENTE : "";
    let localPro = PlanContratacionCAL[0].fc_PRODUCTO ? PlanContratacionCAL[0].fc_PRODUCTO : "";
    let localCan = PlanContratacionCAL[0].fc_CANAL ? PlanContratacionCAL[0].fc_CANAL : "";
    let localC2C = PlanContratacionCAL[0].fc_ID_C2C ? PlanContratacionCAL[0].fc_ID_C2C : "";
    return (
        <div className="modalClaro mSolicitar">
            <div className="mSolicitarCont">
                {
                    title ?
                        <p className="mSolTitle">{title}:</p>
                        : null
                }
                <p className="mSolTitle">{name}</p>
                <div className="mSolBundle">
                    {
                        fc_DESCRIPCION.includes("Televi") || fc_DESCRIPCION.includes("televi")
                            ? <p><i className="ico-tv"></i><span>TV</span></p>
                            : null
                    }
                    {
                        fc_DESCRIPCION.includes("Internet") || fc_DESCRIPCION.includes("internet")
                            ? <p><i className="ico-tv"></i><span>Internet</span></p>
                            : null
                    }
                    {
                        fc_DESCRIPCION.includes("Telef") || fc_DESCRIPCION.includes("telef")
                            ? <p><i className="ico-tv"></i><span>Telefónia</span></p>
                            : null
                    }
                </div>
                {/* <p className="mSolDesc">{fc_DESCRIPCION}</p> */}
                {
                    !callCond && !alertCond ?
                        <div className="mSolSubs" id="mSolOptions">
                            <p>Selecciona una opcion de contratación:</p>
                            {
                                PlanContratacionCLN.length
                                    ?
                                    <a className="btn btnPrimario withIco" href={`${PlanContratacionCLN[0].fc_URL}`}>
                                        <i className="ico-shopping-cart"></i>
                                        <span>Contrata Online</span>
                                    </a>
                                    : null
                            }
                            {
                                PlanContratacionCHV.length
                                    ?
                                    <a className="btn btnPrimario withIco" href="#!" onClick={()=>handleOpenSales()}>
                                        <i className="ico-message-circle"></i>
                                        <span>Chat de Ventas</span>
                                    </a>
                                    : null
                            }
                            {
                                PlanContratacionCAL.length
                                    ?
                                    <a className="btn btnPrimario withIco" id="callMeNowBtn" href="#!" onClick={() => {setCallCond(c => !c); setSpanSending(false); setIdTrans(formatoFecha(new Date(), 'ddmmyyHHmissms')) }}>
                                        <i className="ico-phone"></i>
                                        <span>Llámenme Ahora</span>
                                    </a>
                                    : null
                            }
                            {
                                PlanContratacionWHA.length && (isMobile || isNegocios)
                                    ?
                                    <a className="btn btnPrimario withIco" href={`${PlanContratacionWHA[0].fc_URL}`}>
                                        <i className="ico-whatsapp-s"></i>
                                        <span>Whatsapp</span>
                                    </a>
                                    : null
                            }
                          <br></br>
                          {  !pageNoLlamar && fi_SERVICIO >= 15 && fi_SERVICIO <= 23 ? <a href="tel:800000197">O llama al 800 000 197</a> : "" }
                        </div>
                        : <></>
                }
                {
                    callCond ?
                        <div className="mSolForm">
                            <p>Ingresa tu número de contacto:</p>
                            <form action="">
                                {
                                    showInputRut &&
                                    <>
                                    <fieldset className='fullInput' style={{marginBottom:"20px"}}>
                                        <label>RUT</label>
                                        <div className={'inputField inputPhone val ' + statusClass}>
                                            <input
                                                id="localRut1"
                                                name="localRut1"
                                                type="text"
                                                placeholder='Ingresa tu RUT'
                                                value={rut} 
                                                maxLength={9}
                                                onChange={(e) => onChangeNumber(e.target.value,setRut,9)}
                                                onFocus={(e) => onFocusRut(e.target.value)}
                                                onBlur={(e) => onBlurRut(e.target.value)}
                                                disabled={inputDisabled}
                                            />
                                        </div>
                                        {errors?.rut?.message &&
                                            <div className="notification error">
                                                <p>
                                                    {errors?.rut?.message}
                                                </p>
                                            </div>
                                        }
                                    </fieldset>
                                    <br/>
                                    </>
                                }
                                <fieldset style={{display: showSelectTelTipo ? 'none': ''}}>
                                    <span className="select big">
                                        <select defaultValue={localTelefono_tipo} onChange={phoneTypeComparador} disabled={inputDisabled} id="SelectTelTipo">
                                            <option value="movil">M&oacute;vil</option>
                                            <option value="fijo">Fijo</option>
                                        </select>
                                    </span>
                                </fieldset>
                                <fieldset className={showSelectTelTipo ? 'fullInput': ''}>
                                    <div className={'inputPhone val ' + statusClass}>
                                        <span className="phoneCode">{phoneCode}</span>
                                        <div className="inputField">
                                            <input
                                                id="localTelefono"
                                                name="localTelefono"
                                                type="text"
                                                maxLength={maxlengthPhone}
                                                onChange={validateOnlyNumbersComparador}
                                                placeholder="Ingresa tu n&uacute;mero"
                                                value={localTelefono}
                                                disabled={inputDisabled}
                                            />
                                            <input type='hidden' name='idTrans' id='idTrans' value={idTrans}/>
                                            <input name="localmail" id="localmail" type="hidden" value={mailVal}/>
                                            <input name="localRut1" id="localRut1" type="hidden" value={rutVal}/>
                                            <input name="localServicio" id="localServicio" type="hidden" value={localSer} />
                                            <input name="localSegmento" id="localSegmento" type="hidden" value={localSeg} />
                                            <input name="localCliente" id="localCliente" type="hidden" value={localCli} />
                                            <input name="id_trans" id="id_trans" type="hidden" value={idTrans}/>
                                            <input name="localProducto" id="localProducto" type="hidden" value={localPro} />
                                            <input name="localCanal" id="localCanal" type="hidden" value={localCan} />
                                            <input name="localId_c2c" id="localId_c2c" type="hidden" value={localC2C} />
                                        </div>
                                    </div>
                                    <span className="errorField">{errorField}</span>
                                </fieldset>
                                <div className="mSolFormActions">
                                    {/* <button className="btn btnPrimario" type="submit" onClick={() => { setCallCond(c => !c); setAlertCond(c => !c); magazineSubmitPlanComparador(); }}>Enviar</button> */}
                                    {
                                        spanSending ?
                                            statusClass === ""
                                                ?
                                                <span className="btn btnPrimario">Enviando...</span>
                                                : null
                                        :
                                        <button className="btn btnPrimario" type="button" onClick={() => { magazineSubmitPlanComparador(c => !c, 'sendDataPersonas', PlanContratacionCAL[0]); }}>Enviar</button>
                                    }
                                    <a className="textLink" id="mSolOptionsBtn" href="#!" onClick={() => { setCallCond(c => !c); clearClassStatusPlanComparador(true); }}>Ver otras opciones</a>
                                </div>
                            </form>
                        </div>
                        : <></>
                }
                {
                    alertCond && statusClass !=="" ?
                        <div className="c2cAlerts">
                            <div className={'notification ' + statusClass}>
                                <p>{Parser(notification)}</p><span className="ico-x"></span>                                
                            </div>
                            {/* <div className="notification error">
                                <p>{notification}</p><span className="ico-x"></span>
                            </div> */}
                            {/* <a className="textLink" href="#!" onClick={() => { setCallCond(c => !c); setAlertCond(c => !c); clearClassStatusPlanComparador(); }}>Vuelve a enviarnos tu número</a> */}
                        </div>
                        : <></>
                }
            </div>
        </div>
    )
}

MSolicitarPartModal.defaultProps = {
    openSales: ()=>{},
}

MSolicitarPartModal.propTypes = {
    modalRef: PropTypes.oneOfType([
        PropTypes.func, 
        PropTypes.shape({ current: PropTypes.any })
    ])
}

export default MSolicitarPartModal
